import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import { createContext, useEffect } from "react";
import { Global } from "@emotion/react";
import { ChatToDesk } from "@/components/elements/chat-to-desk";
import { commonTheme, GlobalStyles } from "@mafin/ui-themes";
import TagManager from "react-gtm-module";
import "../styles/globals.css";
import { useSaveUtmToCookies } from "@/utils/hooks/use-save-utm-to-cookies";
import { MAFIN_MEDIA_ROUTES } from "@/components/mafin-media/utils/constants";
import { useSaveAdmitadToCookies } from "@/utils/hooks/use-save-admitad-to-cookies";
import { AuthProvider, Auth } from "@mafin/utilities";
import { ProfileProvider } from "@/utils/profile/profile-provider";
import { IGlobalContext } from "@/utils/globalContext/types";

export const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);

const MyApp = ({ Component, pageProps }: AppProps) => {
  useSaveUtmToCookies();
  useSaveAdmitadToCookies();

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.NEXT_PUBLIC_GTM_KEY || "",
    };

    if (typeof tagManagerArgs !== undefined) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  // Prevent Next bug when it tries to render the [[...slug]] route
  const router = useRouter();

  if (router.asPath === "/[[...slug]]") {
    return null;
  }

  const apiHost = process.env.NEXT_PUBLIC_WEBAPI_URL || "";

  return (
    <>
      <GlobalStyles />
      <Global styles={commonTheme} />
      {/* Global site metadata */}
      <DefaultSeo titleTemplate="%s" title="Mafin" description="Современный сервис страхования" />
      {/* Display the content */}
      <AuthProvider Auth={Auth} customApiHost={apiHost}>
        <ProfileProvider>
          <Component {...pageProps} />
        </ProfileProvider>
      </AuthProvider>
      {!router.route.startsWith(MAFIN_MEDIA_ROUTES.media) && router.query?.absolute !== "true" && <ChatToDesk />}
    </>
  );
};

export default MyApp;
