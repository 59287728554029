import { Breadcrumb } from "@/components/sections/types";

export const MAFIN_MEDIA_ROUTES = {
  media: "/media",
  tags: "/media/tags",
  tests: "/media/testy",
  team: "/media/team",
  news: "/media/news",
};

export const ARTICLES_PAGINATION_QUERY_PARAM = "articlesCount";
export const NEWS_PAGINATION_QUERY_PARAM = "newsCount";
export const TAG_QUERY_PARAM = "tag";

export const BASE_BREAD_CRUMBS: Breadcrumb[] = [
  {
    link: "/",
    name: "Mafin",
  },
  {
    link: MAFIN_MEDIA_ROUTES.media,
    name: "Mafin Media",
  },
];
