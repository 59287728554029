import { IPolicy, useAuth } from "@mafin/utilities";
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { IShortCalculation } from "@/utils/api/types/calculations";
import { getLastCalculation } from "@/utils/api/requests/calculations";
import { Subscription, SubscriptionProduct } from "@/utils/api/types/subscriptions";
import { getSubscriptionsList } from "@/utils/api/requests/subscriptions";
import { getPolicies, getPoliesFromRoRBackend } from "@/utils/api/requests/policies";

export type Categories = "policy" | "calc" | "subscriptions";

export interface ICalculations {
  kasko?: IShortCalculation;
  osago?: IShortCalculation;
}

interface IProfileProvider {
  policies: IPolicy[];
  calculations: ICalculations;
  subscriptionProducts: SubscriptionProduct[];
  subscriptions: Subscription[];
  setPolicies: Dispatch<SetStateAction<IPolicy[]>>;
  setCalculations: Dispatch<SetStateAction<ICalculations>>;

  reloadSubscriptions(): void;
}

const ProfileContext = createContext<IProfileProvider>({
  policies: [],
  calculations: {},
  subscriptions: [],
  subscriptionProducts: [],
  setPolicies: () => null,
  setCalculations: () => null,
  reloadSubscriptions: () => null,
});

export const ProfileProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [policies, setPolicies] = useState<IPolicy[]>([]);
  const [calculations, setCalculations] = useState<ICalculations>({});
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [subscriptionProduces, setSubscriptionProducts] = useState<SubscriptionProduct[]>([]);

  const { user } = useAuth();

  const isLogined = !!user.profile?.phone && !!user.profile?.firstName;

  useEffect(() => {
    const getData = async () => {
      if (isLogined) {
        try {
          const webApiPolicies = (await getPolicies()) || [];
          const rorPolicies = (await getPoliesFromRoRBackend()) || [];

          setPolicies([...webApiPolicies, ...rorPolicies]);
        } catch (e) {
          console.log("error load policies");
        }

        setSubscriptions(isLogined ? (await getSubscriptionsList())?.data?.subscriptions || [] : []);
        setSubscriptionProducts(isLogined ? (await getSubscriptionsList())?.data?.products || [] : []);

        const lastKaskoCalculation = await getLastCalculation("kasko");
        const lastOsagoCalculation = await getLastCalculation("osago");

        setCalculations({
          ...(lastKaskoCalculation && { kasko: lastKaskoCalculation }),
          ...(lastOsagoCalculation && { osago: lastOsagoCalculation }),
        });
      } else {
        setPolicies([]);
        setCalculations({});
      }
    };

    getData();
  }, [isLogined]);

  const reloadSubscriptions = async () => {
    setSubscriptions(isLogined ? (await getSubscriptionsList())?.data?.subscriptions || [] : []);
  };

  return (
    <ProfileContext.Provider
      value={{
        policies,
        calculations,
        subscriptions,
        setPolicies,
        setCalculations,
        reloadSubscriptions,
        subscriptionProducts: subscriptionProduces,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
