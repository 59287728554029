import { useEffect } from "react";

const UTM_TAGS_ARRAY = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "yclid",
  "gclid",
  "fbclid",
] as const;

type UtmTags = Partial<Record<typeof UTM_TAGS_ARRAY[number], string>>;

const getUtmQueriesFromUrl = (): UtmTags => {
  const queryString = new URLSearchParams(window.location.search);

  const utmTagsObject: UtmTags = {};

  UTM_TAGS_ARRAY.forEach((tag) => {
    const tagValue = queryString.get(tag);

    if (queryString.has(tag) && tagValue) {
      utmTagsObject[tag] = tagValue;
    }
  });

  return utmTagsObject;
};

export const useSaveUtmToCookies = () => {
  useEffect(() => {
    const tags = getUtmQueriesFromUrl();

    Object.entries(tags).forEach(([key, value]) => {
      const date = new Date();
      const EXPIRES_DAYS = 365;

      date.setTime(date.getTime() + EXPIRES_DAYS * 24 * 60 * 60 * 1000);

      const expires = `expires=${date.toUTCString()}`;

      document.cookie = `${key}=${value}; ${expires}; path=/`;
    });
  }, []);
};
