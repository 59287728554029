import { getAccessToken, HEADERS } from "@mafin/utilities";
import { SubscriptionsListDTO } from "@/utils/api/types/subscriptions";

const getSubscriptionApiUrl = () => {
  return process.env.NEXT_PUBLIC_SUBSCRIPTIONS_URL || "";
};

export const getGetPaidPageUrl = () => {
  return process.env.NEXT_PUBLIC_SUBSCRIPTIONS_GET_PAID_URL || "";
};

export const getSubscriptionsList = async (): Promise<SubscriptionsListDTO> => {
  try {
    const options: RequestInit = {
      cache: "no-cache",
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    };

    const response = await fetch(`${getSubscriptionApiUrl()}/personal/subscription/list`, options);

    const json: SubscriptionsListDTO = await response.json();

    return json;
  } catch (e) {
    throw new Error("Cannot load the subscriptions list");
  }
};

export const cancelSubscription = async (id: string): Promise<void> => {
  try {
    const options: RequestInit = {
      cache: "no-cache",
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${getAccessToken()}`,
      },
      method: "PATCH",
    };

    await fetch(`${getSubscriptionApiUrl()}/subscription/${id}/suspend`, options);
  } catch (e) {
    throw new Error("Cannot suspend subscription");
  }
};
