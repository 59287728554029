import { CALCULATION_LIST_URL, KASKO_CALCULATION_URL, OSAGO_CALCULATION_URL } from "@/utils/constants/paths";
import { getAccessToken, HEADERS } from "@mafin/utilities";
import { TCalculation, ICalculation, IShortCalculation } from "@/utils/api/types/calculations";

const getApiUrl = () => process.env.NEXT_PUBLIC_WEBAPI_URL;

export const getCalculations = async (type: TCalculation): Promise<IShortCalculation[] | null> => {
  try {
    const options: RequestInit = {
      method: "GET",
      cache: "no-cache",
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    };

    const response = await fetch(`${getApiUrl() + CALCULATION_LIST_URL}?type=${type}`, options);

    const json = await response.json();

    if ("data" in json) {
      return json.data.calculations;
    }

    return [];
  } catch (e) {
    console.error("load calculations error", e);

    return null;
  }
};

export const getLastCalculation = async (type: TCalculation) =>
  (await getCalculations(type))
    ?.filter((i) => i.properties.status !== "policy_activated")
    .at(type === "kasko" ? 0 : -1);

export const getCalculationData = async <T extends TCalculation>(
  type: T,
  id: string
): Promise<ICalculation<T> | null> => {
  try {
    const options: RequestInit = {
      method: "GET",
      cache: "no-cache",
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    };

    const response = await fetch(
      `${getApiUrl() + (type === "kasko" ? KASKO_CALCULATION_URL : OSAGO_CALCULATION_URL)}/${id}`,
      options
    );

    const json = await response.json();

    if ("data" in json) {
      return json.data[`${type}_calculation`];
    }

    return null;
  } catch (e) {
    console.error("load calculations error", e);

    return null;
  }
};
