// export const BASE_URL = process.env.NEXT_PUBLIC_SITE_URL || "https://mafin.ru";
export const BASE_URL = "https://mafin.ru";
export const KASKO_URL = "/kasko";
export const KASKO_MOBILE_URL = "/kasko-mobile";
export const OSAGO_URL = "/osago";
export const MOBILE_APP_URL = "/mobile-app";
export const KASKO_CALC_URL = "/ekasko/calc";
export const OSAGO_CALC_URL = "/osago/calc";
export const POLICY_SEARCH_URL = "/policy/search";
export const CALCULATION_LIST_URL = "/calculation/list";
export const KASKO_CALCULATION_URL = "/kasko-calculation";
export const OSAGO_CALCULATION_URL = "/osago-calculation";
export const ACCOUNT_URL = "/account";
export const AUTH_ABSOLUTE_URL = "/auth-absolute";
export const IS_ABSOLUTE_QUERY = "?absolute=true";
export const ACCOUNT_POLICY_URL = "/account/policy";
export const ACCOUNT_CALC_URL = "/account/calc";
export const ACCOUNT_SUBSCRIPTIONS_URL = "/account/subscriptions";
export const MAIN_URL = "/";
export const STRAHOVANIE_IPOTEKI_CALC = "/strahovanie-ipoteki/calc";
export const STRAHOVKARU_MORTGAGE_PARI_CALCULATE = "/mvp/mortgage/pari/calculate";
export const STRAHOVKARU_MORTGAGE_PARI = "/mvp/mortgage/pari";
export const STRAHOVANIE_IPOTEKI_SBERBANK = "/strahovanie-ipoteki/sberbank";
export const STRAHOVANIE_IPOTEKI_CALC_FLAT = "/strahovanie-ipoteki/calc/new/flat";
export const STRAHOVANIE_IPOTEKI_CALC_FULL = "/strahovanie-ipoteki/calc/new/full";
export const STRAHOVANIE_IPOTEKI_CALC_LIFE = "/strahovanie-ipoteki/calc/new/life";
