import { useRouter } from "next/router";

export const useSaveAdmitadToCookies = () => {
  const EXPIRES_DAYS = 30;
  const {
    query: { utm_source, utm_campaign, admitad_uid },
  } = useRouter();

  if (utm_source && utm_campaign && admitad_uid && typeof document !== "undefined") {
    const date = new Date();

    date.setDate(date.getDate() + EXPIRES_DAYS);

    document.cookie = `cpa=${JSON.stringify({
      utm_source,
      utm_campaign,
      admitad_uid,
    })}; expires=${date.toUTCString()}; path=/`;
  }
};
