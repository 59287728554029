import { City, CityLight, Expert, FeedbackBody, InsuranceCaseBody, Page } from "@/utils/api/types/main-site";
import { getServerSideStrapiURL } from "@/utils/api/helpers";
import { IGlobalContext, Product } from "@/utils/globalContext/types";
import { getArticles, getNews } from "@/utils/api/requests/mafin-media";
import { ContentSection } from "@/components/sections/types";
import { PageWithCollectionItems } from "@/utils/routing/routing";
import { ITag } from "@/utils/api/types/mafin-media";

const API_URL = process.env.NEXT_PUBLIC_WEBAPI_URL || "";

export const getClientSideStrapiURL = (path: string) => {
  return `/api/strapi${path}`;
};

export const getRoRApiUrl = () => {
  if (process.env.NEXT_PUBLIC_RUBY_URL) {
    return process.env.NEXT_PUBLIC_RUBY_URL;
  }

  return "";
};

/**
 * Helper to make GET requests to Strapi
 */
export const fetchAPI = async (path: string, options = {}) => {
  const isServer = typeof window === "undefined";

  const defaultOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };
  const requestUrl = isServer ? getServerSideStrapiURL(path) : getClientSideStrapiURL(path);

  const fetchRetry = (n = 5, timeout = 10000): Promise<Response> => {
    return fetch(requestUrl, mergedOptions).catch(async (e) => {
      if (n < 1) {
        throw e;
      }
      await new Promise<void>((resolve) => {
        setTimeout(resolve, timeout);
      });

      return fetchRetry(n - 1);
    });
  };

  const response = await fetchRetry();

  if (!response.ok) {
    throw new Error(
      `An error occurred please try again. path: ${path}; 
      requestUrl: ${requestUrl}, response status: ${response.status}
      response status text: ${response.statusText}`
    );
  }

  const data = await response.json();

  return data;
};

/**
 * Server side only!
 */
export const getPageData = async (slug: string, preview = false) => {
  // Find the pages that match this slug
  const pagesData: Page[] = await fetchAPI(`/pages?slug=${slug}&status=published${preview ? "&status=draft" : ""}`);

  // Make sure we found something, otherwise return null
  if (pagesData == null || pagesData.length === 0) {
    return null;
  }

  // Return the first item since there should only be one result per slug
  return pagesData[0];
};

/**
 * Server side only! Get site data from Strapi (metadata, navbar, footer...)
 */
export const getGlobalData = async (): Promise<IGlobalContext> => {
  const global = await fetchAPI("/global");

  return global;
};

export const getExperts = async (): Promise<Expert[] | undefined> => {
  try {
    const res = await fetch(`${API_URL}/experts`);

    return await res.json();
  } catch (e) {
    console.log("get experts error", e);
  }
};

export const getProducts = async (): Promise<Product[]> => {
  try {
    return await fetchAPI("/products");
  } catch (e) {
    console.error("get products error", e);

    return [];
  }
};

export const getCities = async (): Promise<City[]> => {
  try {
    return (await fetchAPI("/cities")) || [];
  } catch (e) {
    console.error("get cities error", e);

    return [];
  }
};

export const getCitiesLight = async (): Promise<CityLight[]> => {
  try {
    return (await fetchAPI("/cities/light")) || [];
  } catch (e) {
    console.error("get citiesLight error", e);

    return [];
  }
};

export const getCity = async (id: number): Promise<City | undefined> => {
  try {
    return await fetchAPI(`/cities/${id}`);
  } catch (e) {
    console.error("get products error", e);
  }
};

interface GetPagesProps {
  turbo?: boolean;
  limit?: number;
}

export const getPages = async ({ turbo = false, limit = 500 }: GetPagesProps = {}): Promise<Page[]> => {
  try {
    return await fetchAPI(`/pages?_limit=${limit}&status=published${turbo ? "&turbo=true" : ""}`);
  } catch (e) {
    console.error("get pages error", e);

    return [];
  }
};

export const getTemplatePages = async (): Promise<Page[]> => {
  try {
    return await fetchAPI(`/template-pages`);
  } catch (e) {
    console.error("get templates error", e);

    return [];
  }
};

export const getTemplatePage = async (slug: string, preview: boolean): Promise<Page | undefined> => {
  try {
    return (await fetchAPI(`/template-pages?slug=${slug}&_publicationState=${preview ? "preview" : "live"}`))[0];
  } catch (e) {
    console.error("get template error", e);
  }
};

export const sendSmsCode = async (
  phone: string,
  recaptcha: string
): Promise<{ data: { retry_time: number; login_challenge: string } } | null> => {
  try {
    const res = await fetch(`${API_URL}/getSmsCode`, {
      method: "post",
      headers: {
        "captcha-token": recaptcha,
      },
      body: JSON.stringify({ mobile: phone }),
    });

    return await res.json();
  } catch (e) {
    console.log("getSmsCode error", e);
  }

  return null;
};

export const verifySmsCode = async (phone: string, loginChallenge: string, code: string) => {
  try {
    const res = await fetch(`${API_URL}/verify`, {
      method: "post",
      body: JSON.stringify({
        mobile: phone,
        login_challenge: loginChallenge,
        code,
      }),
    });

    return await res.json();
  } catch (e) {
    console.log("getSmsCode error", e);
  }

  return null;
};

export const sendInsuranceCase = async (data: InsuranceCaseBody) => {
  try {
    return await fetch("https://hook.integromat.com/cmnjhjtp5qm2gxibf7encokti6jwrcr4", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
  } catch (e) {
    console.log("send insurance case error", e);
  }

  return null;
};

export const sendFeedback = (data: FeedbackBody) =>
  fetch(getClientSideStrapiURL("/feedback"), {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const getAdditionalSectionData = (pageData: PageWithCollectionItems): Promise<ContentSection[]> => {
  const { contentSections, linkSet } = pageData;

  return Promise.all(
    contentSections.map(async (section) => {
      if (section.__component === "sections.cross-links" && linkSet) {
        return { ...section, linkSet };
      }

      if (section.__component === "sections.actual-media-articles" && section.mediaTag) {
        return {
          ...section,
          articles: await getArticles(4, { tagSlug: (section.mediaTag as ITag).slug }),
        };
      }

      if (section.__component === "sections.kasko-mobile") {
        return { ...section, articles: await getArticles(3, { tagSlug: "kasko" }) };
      }

      if (section.__component === "sections.news-by-tag" && section.mediaTag) {
        return { ...section, news: await getNews(4, { tagSlug: (section.mediaTag as ITag).slug }) };
      }

      if (section.__component === "sections.partners-pso") {
        return { ...section, partners: pageData.city.kaskoPsoPartners };
      }

      if (section.__component === "sections.cities-chips") {
        return { ...section, city: pageData.city, product: pageData.product, cities: await getCities() };
      }

      return section;
    })
  );
};
