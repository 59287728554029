import { POLICY_SEARCH_URL } from "@/utils/constants/paths";
import { getAccessToken, HEADERS, IPolicy } from "@mafin/utilities";
import { KASKO, OSAGO } from "@/utils/constants/strings";
import { getRoRApiUrl } from "@/utils/api/requests/main-site";

const getApiUrl = () => process.env.NEXT_PUBLIC_WEBAPI_URL;

export type Role = "owner" | "driver";

export type Document<T extends Role> = Record<
  T,
  [
    { type: T extends "owner" ? "passport" : "driver_license" } & {
      field: "series";
      value: string;
    },
    { type: T extends "owner" ? "passport" : "driver_license" } & {
      field: "number";
      value: string;
    }
  ]
>;

export interface SearchParameters {
  subject_roles: Role[];
  insurer_phone: string;
  user_birth_date: string;
  documents: Document<"owner"> | Document<"driver">;
}

export const searchPolicies = async (
  body: SearchParameters
): Promise<
  { policies: IPolicy[] } | { message: string; errors: { user_birth_date: string[]; phone: string[] } } | undefined
> => {
  try {
    const options: RequestInit = {
      method: "POST",
      cache: "no-cache",
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({ ...body, policy_types: [OSAGO, KASKO] }),
    };
    const response = await fetch(`${getApiUrl()}${POLICY_SEARCH_URL}`, options);

    const json = await response.json();

    return json.data || json;
  } catch (e) {
    console.error("load policies error", e);

    return undefined;
  }
};

export const getPolicies = async () => {
  const res = await fetch(`${getApiUrl()}/policy/list`, {
    headers: {
      ...HEADERS,
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  const json = await res.json();

  return json?.data?.policies || [];
};
export interface GetPoliciesRORResponse {
  id: string;
  policy_type: string;
  status: string;
  number: string;
  start_date: string;
  expiration_date: string;
  price: number;
  paid_at: string;
  insurance: {
    id: string;
    name: string;
    legal_name: string;
    insurance_key: string;
    description: string;
    logo: string;
    is_active: boolean;
  };
  car: {
    brand_id: number;
    model_id: number;
    title: string;
    manufacture_year: string;
    vin: string;
    grz: string;
    horse_power: number;
  };
  drivers: string[];
  documents: string[];
}

const mapPoliciesFromRorToWebapiFormat = (policies: GetPoliciesRORResponse[]): IPolicy[] => {
  return policies.map((i) => ({
    id: i.id,
    documents: i.documents.map((i) => ({ title: "Полис", type: "policy", properties: { link: i } })),
    drivers: i.drivers.map((i, index) => ({ id: String(index), name: i })),
    expiration_date: i.expiration_date,
    insurance: i.insurance,
    number: i.number,
    object: {
      type: "car",
      properties: i.car,
    } as unknown as IPolicy["object"],
    paid_at: i.paid_at,
    policy_type: i.policy_type,
    price: i.price,
    role: "owner",
    start_date: i.start_date,
    status: i.status === "expired" ? "terminated" : "active",
  })) as IPolicy[];
};

export const getPoliesFromRoRBackend = async () => {
  try {
    const options: RequestInit = {
      cache: "no-cache",
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    };
    const response = await fetch(`${getRoRApiUrl()}/v2/cabinet/user/policies`, options);

    const json: IPolicy[] = mapPoliciesFromRorToWebapiFormat(await response.json());

    return json;
  } catch (e) {
    throw new Error("Cannot load the policies list");
  }
};
