import { add, format } from "date-fns";
import { fetchAPI } from "@/utils/api/requests/main-site";
import {
  IMaterialFilterOptions,
  IArticle,
  IAuthor,
  IMafinMediaSettings,
  IRubric,
  ITag,
  INews,
} from "@/utils/api/types/mafin-media";

const getAmpersand = (queryString: string) => (queryString ? "&" : "");

const makeArticleFilterQuery = (options: IMaterialFilterOptions) => {
  let result = "";

  if (options.tagSlug) {
    result = `${getAmpersand(result)}tags.slug=${options.tagSlug}`;
  }

  if (options.rubricSlug) {
    result += `${getAmpersand(result)}rubric.slug=${options.rubricSlug}`;
  }

  if (options.authorSlug) {
    result += `${getAmpersand(result)}author.slug=${options.authorSlug}`;
  }

  if (options.exclude) {
    result += `${getAmpersand(result)}slug_ne=${options.exclude}`;
  }

  return result ? `&${result}` : result;
};

const getDateFilter = (ampersand = true) => {
  const date = add(new Date(), { days: 1 });

  return `${ampersand ? "&" : ""}publicationDate_lte=${format(date, "yyyy-MM-dd")}`;
};

/* tags */
export const getTag = async (slug: string): Promise<ITag | null> => {
  const tags = await fetchAPI(`/media-tags?slug=${slug}`);

  return tags?.length ? tags[0] : null;
};

export const getTags = async (): Promise<ITag[]> => {
  const tags = await fetchAPI(`/media-tags`);

  return tags?.length ? tags : [];
};

export const getTagsWithNews = async (): Promise<ITag[]> => {
  const tags = await fetchAPI(`/media-tags/getTagsWithNews`);

  return tags?.length ? tags : [];
};

/* rubrics */
export const getRubrics = async (preview = false): Promise<IRubric[]> => {
  const rubrics = await fetchAPI(`/media-rubrics?_publicationState=${preview ? "preview" : "live"}`);

  return rubrics?.length ? rubrics : [];
};

export const getRubric = async (slug: string, preview = false): Promise<IRubric | null> => {
  const rubrics = await fetchAPI(`/media-rubrics?slug=${slug}&_publicationState=${preview ? "preview" : "live"}`);

  return rubrics?.length ? rubrics[0] : null;
};

/* articles */
export const getArticle = async (slug: string, preview = false): Promise<IArticle | undefined> => {
  const articles = await fetchAPI(`/media-articles?slug=${slug}&_publicationState=${preview ? "preview" : "live"}`);

  return articles?.length ? articles[0] : undefined;
};

export const getRelevantArticles = async (articleSlug: string, options?: IMaterialFilterOptions) => {
  const articles = await fetchAPI(
    `/media-articles?_limit=5${getDateFilter()}&_sort=publicationDate:DESC${
      options ? `${makeArticleFilterQuery(options)}` : ""
    }`
  );

  const result: IArticle[] = articles?.length ? articles.filter((i: IArticle) => i.slug !== articleSlug) : [];

  return result.slice(0, 4);
};

export const getArticles = async (
  size: number,
  options?: IMaterialFilterOptions,
  preview = false
): Promise<IArticle[]> => {
  const articles = await fetchAPI(
    `/media-articles?_limit=${size}${
      options ? `${makeArticleFilterQuery(options)}` : ""
    }&_sort=publicationDate:DESC${getDateFilter()}&_publicationState=${preview ? "preview" : "live"}`
  );

  return articles?.length ? articles : [];
};

export const getArticlesCount = async (options?: IMaterialFilterOptions) => {
  const totalCount: number = await fetchAPI(
    `/media-articles/count?${getDateFilter(false)}${options ? `${makeArticleFilterQuery(options)}` : ""}`,
    {}
  );

  return totalCount || 0;
};

export const getArticlesWithPagination = async (start: number, limit: number, options?: IMaterialFilterOptions) => {
  const articles: IArticle[] = await fetchAPI(
    `/media-articles?_limit=${limit}&_start=${start}${
      options ? `${makeArticleFilterQuery(options)}` : ""
    }&_sort=publicationDate:DESC${getDateFilter()}`,
    {}
  );
  const totalCount: number = await getArticlesCount(options);

  return {
    articles: Array.isArray(articles) ? articles : [],
    totalCount,
  };
};

/* authors */
export const getAuthors = async (preview = false): Promise<IAuthor[] | null> => {
  const authors = await fetchAPI(`/media-authors?_publicationState=${preview ? "preview" : "live"}`);

  return authors?.length ? authors : [];
};

export const getAuthor = async (slug: string, preview = false): Promise<IAuthor | null> => {
  const authors = await fetchAPI(`/media-authors?slug=${slug}&_publicationState=${preview ? "preview" : "live"}`);

  return authors?.length ? authors[0] : null;
};

/* settings */
export const getMainPageSetting = async (): Promise<IMafinMediaSettings | null> => {
  const setting = (await fetchAPI(`/media-main-page`)) as IMafinMediaSettings;

  return setting || null;
};

/* news */
export const getNews = async (size: number, options?: IMaterialFilterOptions, preview = false): Promise<INews[]> => {
  const news = await fetchAPI(
    `/media-news?_limit=${size}${
      options ? `${makeArticleFilterQuery(options)}` : ""
    }&_sort=publicationDate:DESC${getDateFilter()}&_publicationState=${preview ? "preview" : "live"}`
  );

  return news?.length ? news : [];
};

export const getRelevantNews = async (
  slug: string,
  size: number,
  options: IMaterialFilterOptions,
  preview: boolean
): Promise<INews[]> => {
  const news = await getNews(size + 1, options, preview);

  const result: INews[] = news?.length ? news.filter((i: INews) => i.slug !== slug) : [];

  return result.slice(0, size);
};

export const getNewsCount = async (options?: IMaterialFilterOptions) => {
  const totalCount: number = await fetchAPI(
    `/media-news/count?${getDateFilter(false)}${options ? `${makeArticleFilterQuery(options)}` : ""}`,
    {}
  );

  return totalCount || 0;
};

export const getNewsWithPagination = async (start: number, limit: number, options?: IMaterialFilterOptions) => {
  const news: INews[] = await fetchAPI(
    `/media-news?_limit=${limit}&_start=${start}${
      options ? `${makeArticleFilterQuery(options)}` : ""
    }&_sort=publicationDate:DESC${getDateFilter()}`,
    {}
  );
  const totalCount: number = await getNewsCount(options);

  return {
    articles: Array.isArray(news) ? news : [],
    totalCount,
  };
};

export const getOneNews = async (slug: string, preview = false): Promise<INews | undefined> => {
  const news = await fetchAPI(`/media-news?slug=${slug}&_publicationState=${preview ? "preview" : "live"}`);

  return news?.length ? news[0] : undefined;
};

export const getNewsPage = async (
  id: number
): Promise<{ news: INews; rubrics: IRubric[]; tags: ITag[]; relevant: INews[] } | undefined> => {
  const result = await fetchAPI(`/media-news/getNews/${id}`);

  return result || undefined;
};
